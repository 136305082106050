<template>
  <VaLayout v-if="breakpoint.lgUp" class="h-screen overflow-hidden bg-[var(--va-background-secondary)]">
    <template #left>
      <div class="bg-[#7F0909] h-full flex flex-col justify-between" style="width: 45vw">
        <div class="p-4 cursor-pointer">
          <BuymeLogo />
        </div>
        <div class="flex items-center max-w-[90%] m-auto flex-col gap-8">
          <h1 class="text-white text-[30px] text-center">
            "Якість та надійність — наші першочергові пріоритети. Дякую BuyMe за відмінну співпрацю!"
          </h1>
          <div class="flex-col flex items-center">
            <VaImage class="w-16 h-16" :src="Avatar10" />
            <h1 class="text-white mt-4 font-medium">Анна Іванова</h1>
            <p class="text-[#FFFFFFB2] text-sm">Менеджер з постачання</p>
          </div>
          <VaImage class="w-[116px] h-5" :src="Stars" />
        </div>
        <div class="p-4">
          <span class="text-[#FFFFFFB2] text-xs">© BuyMe, 2024</span>
        </div>
      </div>
    </template>
    <template #content>
      <main class="h-full flex items-center justify-center mx-auto max-w-[420px]">
        <RouterView />
      </main>
    </template>
  </VaLayout>

  <VaLayout v-else class="h-screen bg-[var(--va-background-secondary)]">
    <template #content>
      <div class="p-4 bg-white">
        <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px]">
          <div class="flex flex-col w-full items-start">
            <BuymeLogo :dark="true" />
            <RouterLink class="py-4" to="/" aria-label="Visit homepage" />
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'
import BuymeLogo from '../components/BuymeLogo.vue'
import { Avatar10, Stars } from '../assets'

const breakpoint = useBreakpoint()
</script>
